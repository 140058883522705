import { useEffect, useState } from "react";
import PageContainer from "components/Admin/Shared/PageContainer";

import { ButtonGroup, Button } from "reactstrap";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import { array, func, string } from "prop-types";
import Api from "Api";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useHistory } from "react-router-dom";
import Confirm from "components/Utils/Confirm";

const GamesList = () => {
  const history = useHistory();
  const [games, setGames] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const getAllGames = async () => {
    const response = await Api.Games.getAll();
    setGames(response.result);
    setLoaded(true);
  };

  useEffect(() => {
    getAllGames();
  }, []);

  const deleteGames = async (id) => {
    await Api.Games.deleteGames(id);
    getAllGames();
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,

      formatter: (cell, row) => {
        const shareLink = `${window.location.origin}/${row.uid}`;

        const gameLinks = `${shareLink}/${
          `${row.share_link ? row.share_link : ""}`
            .split(" ")
            .join("-")
            .toLowerCase() || ""
        }`;

        return (
          <a href={gameLinks} target="_blank" rel="noreferrer">
            <span style={{ fontSize: "16px", color: "teal" }}>{row.name}</span>
          </a>
        );
      },
    },
    {
      dataField: "total_users",
      text: "Registered",
      align: "center",
      sort: true,
      headerStyle: () => {
        return { width: "110px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        return row.total_users || 0;
      },
    },
    {
      dataField: "is_lock",
      text: "Is Lock?",
      align: "center",
      headerStyle: () => {
        return { width: "150px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        return row.is_lock ? "Yes" : "No";
      },
    },

    {
      dataField: "uid",
      text: "",
      headerStyle: () => {
        return { width: "150px", textAlign: "center" };
      },
      align: "center",
      isDummyField: true,
      formatter: (cell, row) => {
        return (
          <ButtonGroup size="sm">
            <Button
              color="info"
              onClick={() => {
                history.push(`/admin/games?gameId=${row.uid}`);
              }}
            >
              <FaEdit />
            </Button>
            <Confirm
              title="Confirm Removal"
              body="Are you sure want to delete this item?"
              onConfirm={() => {
                deleteGames(row.id);
              }}
            >
              <Button color="danger">
                <FaTrashAlt />
              </Button>
            </Confirm>
          </ButtonGroup>
        );
      },
      isDummy: true,
    },
  ];

  return (
    <PageContainer
      title="Games List"
      menuAction={
        <ButtonGroup size="sm">
          <Button
            color="primary"
            onClick={() => {
              history.push("/admin/games");
            }}
          >
            Add Games
          </Button>
        </ButtonGroup>
      }
    >
      {loaded ? (
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={games}
          columns={columns}
          pagination={paginationFactory({ page: 1, showTotal: true })}
        />
      ) : (
        <div>Loading...</div>
      )}
    </PageContainer>
  );
};

GamesList.propTypes = {
  data: array,
  gameId: string,
  onSave: func,
};

export default GamesList;
