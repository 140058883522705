import PageContainer from "components/Admin/Shared/PageContainer";
import AssetsUploader from "components/Shared/AssetsUploader";
import { func, object } from "prop-types";
import { Col, Row } from "reactstrap";

const Images = ({ onUpload, formdata = {} }) => {
  const onBrowse = (newFile) => {
    onUpload && onUpload(newFile);
  };
  return (
    <PageContainer>
      <Row>
        <Col md={6}>
          REGISTRATION IMAGE
          <AssetsUploader
            name="registration_image"
            useButton
            currentImage={formdata?.registration_image}
            onBrowse={(file) =>
              onBrowse({
                field: "registration_image",
                new_registration_image: file.base64String,
                new_registration_image_fileName: file.new_fileName,
              })
            }
          />
        </Col>
        <Col md={6}>
          BACKGROUND IMAGE
          <AssetsUploader
            name={"bg_image"}
            useButton
            currentImage={formdata?.bg_image}
            onBrowse={(file) =>
              onBrowse({
                field: "bg_image",
                new_bg_image: file.base64String,
                new_bg_fileName: file.new_fileName,
              })
            }
          />
        </Col>
      </Row>
      <hr />
      <strong>CERTIFCATE TEMPLATE</strong>
      <Row>
        <Col md={6}>
          SILVER
          <AssetsUploader
            name={"silver_certificate_template"}
            useButton
            currentImage={formdata?.silver}
            onBrowse={(file) =>
              onBrowse({
                field: "silver",
                new_silver: file.base64String,
                new_silver_fileName: file.new_fileName,
              })
            }
          />
        </Col>
        <Col md={6}>
          BRONZE
          <AssetsUploader
            name="bronze_certificate_template"
            useButton
            currentImage={formdata?.bronze}
            onBrowse={(file) =>
              onBrowse({
                field: "bronze",
                new_bronze: file.base64String,
                new_bronze_fileName: file.new_fileName,
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          GOLD
          <AssetsUploader
            name={"gold_certificate_template"}
            useButton
            currentImage={formdata?.gold}
            onBrowse={(file) =>
              onBrowse({
                field: "gold",
                new_gold: file.base64String,
                new_gold_fileName: file.new_fileName,
              })
            }
          />
        </Col>
      </Row>
    </PageContainer>
  );
};

Images.propTypes = {
  onUpload: func,
  formdata: object,
};

export default Images;
