import moment from "moment";
import _ from "lodash";
import queryString from "query-string";
// import store from "./configureStore";
// import Joi from "@hapi/joi"

export function mockResponse(delay, value) {
  return new Promise(function (resolve) {
    setTimeout(() => {
      resolve(value);
    }, delay);
  });
}

export function consolePrint(...args) {
  if (process.env.NODE_ENV === "development") {
    console.log(...args);
  }
}

export function formatDate(_date, format = "MM/DD/YYYY") {
  // const date = moment(_date, "YYYY-MM-DD");
  const date = moment(_date);
  if (date.isValid()) {
    return date.format(format);
  }
  return null;
}

export function buildQuerystring(params = {}) {
  return queryString.stringify(params);
}

export function getQueryString(thisPropsLocationSearch) {
  return queryString.parse(thisPropsLocationSearch, { ignoreQueryPrefix: true });
}

export function knock(arr, val) {
  if (arr.length === _.pull(arr, val).length) {
    arr.push(val);
  }
  return arr;
}

export function sanitizeFilter(obj = {}) {
  return _.pickBy(
    obj,
    (v) => v !== null && v !== undefined && v !== "" && v.length > 0
  );
}

export function showingTotalRecords(currentPage, totalPage, totalRows) {
  // let perPage = 15;
  // let currentStart = currentPage * perPage;
  // let pager = currentPage === 1 ? 1 : perPage ;

  // if (currentPage === totalPage) {
  //   currentStart = totalRows;
  // }

  // return `Showing result of: ${pager} to ${currentStart} of ${totalRows}`;
  return `Total Result found: ${totalRows}`;
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function buildErrors(result) {
  // let result = Joi.validate({}, schema, {abortEarly: false});
  let errors = {};
  // if (result.error) {
  result.error.details.forEach((detail) => {
    const fieldError = {
      [detail.path.join("")]: {
        error: true,
        message: detail.message,
      },
    };
    errors = Object.assign({}, errors, fieldError);
  });
  return errors;
  // }
}

export async function validateFields(
  data,
  schema,
  options = { abortEarly: false, stripUnknown: true }
) {
  const validation = await schema.validate(data, options);
  if (validation.error) {
    return Object.assign({}, buildErrors(validation), { formValid: false });
  }
  return { formValid: true };
}

export const animateCSS = (element, animation, prefix = "animate__") =>
  // We create a Promise and return it
  new Promise((resolve) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node?.classList?.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node?.classList?.remove(`${prefix}animated`, animationName);
      resolve("Animation ended");
    }

    node?.addEventListener("animationend", handleAnimationEnd, { once: true });
  });

export const reSampleImage = (canvasId) => {
  const canvas = canvasId;
  const context = canvas.getContext("2d");
  const imageObj = new Image();
  let imgData;
  imageObj.src =
    "https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;600&display=swap";
  imageObj.onerror = function () {
    context.textAlign = "center";
    context.font = "3pt 'Fredoka One', cursive";
    context.fillText("Sample 1", 10, 10);
    context.font = "bold 2pt 'Montserrat', sans-serif";
    context.fillText("Sample 2", 20, 20);
    imgData = canvas.toDataURL("image/png");
    console.log("imgData 1", imgData);
  };
  return imgData;
};

export const getCertificateColor = (score, totalQuestion = 0) => {
  const GOLD = totalQuestion;
  const SILVER = totalQuestion - 1;
  const BRONZE = totalQuestion - 2;

  let field = "";
  let message = "";
  let headingTitle = "Well done!";
  switch (score) {
    case GOLD: {
      message = "You have achieved a perfect score.";
      field = "gold";
      break;
    }
    case SILVER: {
      message = "You have achieved an excellent score.";
      field = "silver";
      break;
    }
    case BRONZE: {
      message = "You have achieved a good score.";
      field = "bronze";
      break;
    }
    default: {
      message = `You have achieved a score of ${score}. Feel free to review and take the quiz again so
      you can become a certified Ocean Warrior! Goodluck!`;
      if (score > 5) {
        headingTitle = "Almost there!";
      } else {
        headingTitle = "Try Again!";
      }
    }
  }

  return {
    field,
    message,
    headingTitle,
  };
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function getCertTemplate(image) {
  if (process.env.NODE_ENV === "development") {
    return process.env.PUBLIC_URL + `/images/certificate/gold.jpg`;
  }
  return image;
}
