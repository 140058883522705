/* eslint-disable no-case-declarations */
import * as appAction from "../constants/appAction.js";

const initialState = {};

export function setUser(data) {
  return (dispatch) => {
    dispatch({ type: appAction.SET_USER, payload: data });
  };
}

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case appAction.SET_USER:
      const payload = action.payload;
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
