/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Label, Input, FormFeedback, Col } from "reactstrap";
import DateTime from "react-datetime";

class InputComponent extends React.Component {
  render() {
    const inputProps = {
      ...this.props,
      onChange: this.props.onChange,
      invalid: this.props.error,
      name: this.props.name,
      placeholder: this.props.placeholder,
      value: this.props.value,
      autoComplete: "off",
    };
    const isFormInline = this.props.inline && this.props.inline.length >= 0;
    delete inputProps.error;
    delete inputProps.errorText;

    return this.props.type !== "date" ? (
      <FormGroup className="text-left" row={isFormInline}>
        {this.props.label ? (
          <Label
            for={this.props.name}
            className="form-label"
            {...(isFormInline ? { sm: this.props.inline[0] } : null)}
          >
            {this.props.label}
          </Label>
        ) : null}
        <Col
          {...(isFormInline ? { sm: this.props.inline[1] } : null)}
          className={isFormInline ? "" : "px-0"}
        >
          {this.props.isRequiredTag ? (
            <span style={{ color: "red", "font-weight": "700" }}>&nbsp;*</span>
          ) : null}
          {this.props.type === "select" ? (
            <Input {...inputProps}>{this.props.children}</Input>
          ) : (
            <Input {...inputProps} />
          )}
          {this.props.error ? (
            <FormFeedback>
              {this.props.errorText || "Field is required"}
            </FormFeedback>
          ) : null}
        </Col>
      </FormGroup>
    ) : (
      <FormGroup className="text-left">
        {this.props.label ? (
          <Label for={this.props.name} className="form-label">
            {this.props.label}
          </Label>
        ) : null}
        <DateTime
          closeOnSelect
          closeOnTab
          timeFormat={false}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        {this.props.error ? (
          <FormFeedback>{this.props.errorText || "Field is required"}</FormFeedback>
        ) : null}
      </FormGroup>
    );
  }
}

InputComponent.propTypes = {
  error: PropTypes.bool,
  errorText: PropTypes.string,
  inline: PropTypes.array,
  onChange: PropTypes.func,
};

InputComponent.defaultProps = {
  error: false,
  errorText: "Field is required",
};
// InputComponent.propTypes = {
//   children: PropTypes.node,
//   type: PropTypes.string,
//   size: PropTypes.string,
//   bsSize: PropTypes.string,
//   valid: PropTypes.bool,
//   invalid: PropTypes.bool,
//   tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
//   innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
//   plaintext: PropTypes.bool,
//   addon: PropTypes.bool,
//   className: PropTypes.string,
//   cssModule: PropTypes.object,
// };

export default InputComponent;
