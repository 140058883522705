import React, { useEffect, useState } from "react";
import Main from "../Main";
import Register from "components/Registration";
import Admin from "components/Admin";

import Api from "Api";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// import questions from "constants/questions";
import { setUser } from "reducers/Accounts";
import { setScores } from "reducers/Scores";
import { setVideo } from "reducers/Video";
import NotFound from "components/Notfound";

const MyDefault = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const [showMain, setShowMain] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [notfound, setNotfound] = useState(false);

  const checkUrl = async () => {
    const urlId = params.urlId;
    const videoId = `${params?.urlId}`.split("-")[0];

    if (urlId.includes("/admin")) {
      setShowAdmin(true);
      return;
    } else {
      const video = await Api.Games.get(videoId);
      if (!video) {
        history.push("/not-found");
        setNotfound(true);
      } else {
        dispatch(setVideo(video));

        const response = await Api.Account.CheckSession(videoId);
        if (response.session === true) {
          dispatch(setUser(response.userData?.profile));
          dispatch(setScores(response.userData?.scores));
          setShowMain(true);
        } else {
          setShowRegistration(true);
        }
      }
    }
  };

  useEffect(() => {
    checkUrl();
  }, []);

  const registerSuccess = () => {
    setShowMain(true);

    // history.push("/not-found");
  };

  return (
    <>
      {showMain ? (
        <Main
          onChangeSession={() => {
            setShowMain(false);
            setShowRegistration(true);
          }}
        />
      ) : null}

      {showAdmin ? <Admin /> : null}
      {!showMain && showRegistration ? (
        <Register onSuccess={registerSuccess} gameUid={params.urlId} />
      ) : null}
      {notfound ? <NotFound /> : null}
    </>
  );
};

export default MyDefault;
