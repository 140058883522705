import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import * as appAction from "constants/appAction.js";

const config = {
  whitelist: [appAction.SET_USER, appAction.SET_SCORES],
  channel: "redux_state_sync",
};

import rootReducer from "./reducers";

const middleware = [thunk, createStateSyncMiddleware(config)];

const enhancers = [];
const isDevelopment = process.env.NODE_ENV === "development";
if (
  isDevelopment &&
  typeof window !== "undefined" &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const getMiddleware = () => {
  if (process.env.NODE_ENV === "production") {
    return applyMiddleware(...middleware);
  }
  // Enable additional logging in non-production environments.
  return applyMiddleware(...middleware, createLogger({ collapsed: true }));
};

const store = createStore(rootReducer, compose(getMiddleware(), ...enhancers));
initMessageListener(store);

export default store;
