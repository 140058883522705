// https://www.npmjs.com/package/@u-wave/react-vimeo
import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import { func, string } from "prop-types";
import YouTube from "react-youtube";

const Video = ({ videoLink, onEnd }) => {
  const opts = {
    height: "520",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  let videoSource = null;

  if (videoLink.includes("vimeo.com")) {
    videoSource = videoLink;
  } else {
    videoSource = videoLink.split("v=")?.slice(-1)?.pop();
    if (videoLink.includes("youtu.be")) {
      videoSource = videoLink.split("youtu.be/")[1];
    }
  }

  return (
    <>
      {videoLink.includes("vimeo.com") ? (
        <Vimeo
          video={videoSource}
          autoplay
          onEnd={onEnd && onEnd}
          responsive
          controls={true}
        />
      ) : (
        <YouTube videoId={videoSource} opts={opts} onEnd={onEnd && onEnd} />
      )}
    </>
  );
};

Video.propTypes = {
  onEnd: func,
  videoLink: string,
};

export default Video;
