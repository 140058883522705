import Api from "Api";

const SecondTopNav = () => {
  const logout = () => {
    Api.Account.Logout();
    window.location.reload();
  };
  return (
    <div className="nav-scroller bg-body shadow-sm">
      <nav className="nav nav-underline" aria-label="Secondary navigation">
        <a className="nav-link" aria-current="page" href="#">
          Dashboard
        </a>
        <a className="nav-link" href="/admin/games-list">
          Gamified
          {/* <span className="badge bg-light text-dark rounded-pill align-text-bottom">
            27
          </span> */}
        </a>
        <a className="nav-link" href="/admin/users">
          Users
        </a>

        <a className="nav-link" href="#" onClick={logout}>
          Logout
        </a>
      </nav>
    </div>
  );
};

export default SecondTopNav;
