import { AdminWrapper, AdminGlobalStyle } from "./styles";
import { TopNav, SecondTopNav } from "./Headers";
import { useHistory, useParams } from "react-router-dom";
import { Games, GamesList } from "./Pages/Games";
import { Userslist } from "./Pages/Users";
import { useDispatch } from "react-redux";
import Api from "Api";
import { setUser } from "reducers/Accounts";
import { useEffect, useState } from "react";
import LoginAdmin from "./Pages/Login";

const Admin = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [superUser, setSuperUser] = useState(false);
  const [isChecking, setChecking] = useState(true);

  const checkSession = async () => {
    const response = await Api.Account.CheckSession("admin");
    if (response.session === true && response.userData?.profile?.role === "admin") {
      setSuperUser(true);
    } else {
      setSuperUser(false);
      history.push("/admin/login");
    }
    setChecking(false);
  };

  useEffect(() => {
    checkSession();
  }, []);

  const renderPage = () => {
    if (superUser) {
      switch (params.pages) {
        case "games-list":
          return <GamesList />;
        case "games":
          return <Games />;
        case "users":
          return <Userslist />;
        case "login":
          return <LoginAdmin />;
        default:
          return <div>Not Found</div>;
      }
    } else {
      return <LoginAdmin />;
    }
  };
  return (
    <>
      <AdminGlobalStyle />
      <AdminWrapper>
        <TopNav />
        {!isChecking && (
          <>
            {superUser && <SecondTopNav />}

            <main className="container">{renderPage()}</main>
          </>
        )}
      </AdminWrapper>
    </>
  );
};

export default Admin;
