import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import PageContainer from "components/Admin/Shared/PageContainer";
import Switch from "react-switch";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
  ButtonGroup,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import toastr from "toaster";

import Questions from "./Questions";
import Images from "./Images";
import Settings from "./Settings";
import { useDispatch } from "react-redux";
import { showLoader } from "reducers/Loader";
import Api from "Api";
import { useHistory } from "react-router-dom";
import qs from "query-string";
const TABS = {
  IMAGES: 1,
  QUESTIONS: 2,
  SETTINGS: 3,
  EMAIL: 3,
};

const Games = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef(null);
  const gameId = qs.parse(history.location.search)?.gameId || "";

  const [activeTab, setActiveTab] = useState(TABS.IMAGES);
  const [isPosting, setPosting] = useState(false);
  const [formData, setFormData] = useState({});
  const [imageId, setImageId] = useState(Math.random());
  // console.log(gameId);

  useEffect(() => {
    dispatch(showLoader(isPosting));
  }, [isPosting]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageUpload = (file) => {
    setFormData({ ...formData, ...file });
  };

  const validateForm = () => {
    formRef.current.submit();
  };

  const onSave = async () => {
    const payload = {
      ...formData,
      settings: JSON.stringify(formData?.settings || {}),
    };
    setPosting(true);
    const response = await Api.Games.save(payload);
    setPosting(false);

    if (response.mode === "insert") {
      history.push(`/admin/games?gameId=${response.gameId}`);
      fetchGameInfo(response.gameId);
    } else {
      fetchGameInfo(gameId);
      setImageId(Math.random());
    }
    toastr.success("Game saved successfully");
  };

  const fetchGameInfo = async (id) => {
    const response = await Api.Games.get(id);
    setFormData(response);
  };

  useEffect(() => {
    if (gameId !== "") {
      fetchGameInfo(gameId);
    }
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const isEditMode = gameId !== "";
  const shareLink = `${window.location.origin}/${formData.uid}`;

  const gameLinks = isEditMode
    ? `${shareLink}/${
        `${formData.share_link ? formData.share_link : ""}`
          .split(" ")
          .join("-")
          .toLowerCase() || ""
      }`
    : null;

  return (
    <PageContainer
      title={isEditMode ? `Edit Game - ${formData.name}` : "Create Game"}
      menuAction={
        <ButtonGroup size="sm">
          <Button color="success" onClick={validateForm} disabled={isPosting}>
            Save
          </Button>
          {/* <Button color="danger">Delete</Button> */}
          <Button
            color="info"
            onClick={() => (window.location.href = "/admin/games-list")}
          >
            Back to list
          </Button>
        </ButtonGroup>
      }
    >
      {isEditMode && (
        <div className="d-flex flex-row mb-2">
          Share Link :{" "}
          <a
            className="text-info pl-2 ml-3"
            onClick={() => window.open(gameLinks, "_blank")}
            rel="noreferrer"
          >
            {gameLinks}
          </a>{" "}
        </div>
      )}

      <AvForm
        ref={formRef}
        onValidSubmit={onSave}
        onInvalidSubmit={(i) => console.log("INVALID, ", i)}
      >
        <Row>
          <Col md={12}>
            <AvField
              name="name"
              label="Name"
              errorMessage="Invalid Name"
              value={formData?.name || ""}
              required
              onChange={onChange}
            />
          </Col>

          <Col md={12}>
            <AvField
              name="video_link"
              label="Video Link"
              errorMessage="Invalid Link"
              required
              value={formData?.video_link || ""}
              onChange={onChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>Lock Video</Label>
              <br />
              <Switch
                onChange={(v) => setFormData({ ...formData, is_lock: v })}
                checked={formData?.is_lock}
              />{" "}
            </FormGroup>
          </Col>
          {isEditMode && (
            <Col md={12}>
              <FormGroup>
                <Label>Game Link</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <Button type="button">{shareLink}/</Button>
                  </InputGroupAddon>
                  <Input
                    name="share_link"
                    onChange={onChange}
                    value={formData?.share_link}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          )}
        </Row>
      </AvForm>
      <hr />

      {isEditMode && (
        <>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === TABS.IMAGES })}
                onClick={() => {
                  toggle(TABS.IMAGES);
                }}
              >
                IMAGES
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === TABS.QUESTIONS })}
                onClick={() => {
                  toggle(TABS.QUESTIONS);
                }}
              >
                QUESTIONS ( {formData?.questions?.length || 0} )
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === TABS.SETTINGS })}
                onClick={() => {
                  toggle(TABS.SETTINGS);
                }}
              >
                SETTINGS
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={TABS.QUESTIONS}>
              <Row>
                <Col sm="12">
                  <Questions
                    data={formData?.questions || []}
                    gameId={gameId}
                    onSave={() => {
                      fetchGameInfo(gameId);
                    }}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={TABS.IMAGES}>
              <Row>
                <Col sm="12">
                  <Images
                    key={imageId}
                    formdata={formData}
                    onUpload={(file) => handleImageUpload(file)}
                  />
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId={TABS.SETTINGS}>
              <Row>
                <Col sm="12">
                  <Settings
                    video={formData}
                    demoImg={{
                      gold: formData?.gold,
                      silver: formData?.silver,
                      bronze: formData?.bronze,
                    }}
                    onSettingChange={(changes) => {
                      setFormData({ ...formData, settings: changes });
                    }}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </>
      )}
    </PageContainer>
  );
};

export default Games;
