import { combineReducers } from "redux";

import * as NotificationApi from "./Notification";
import * as Loader from "./Loader";
import * as Accounts from "./Accounts";
import * as Scores from "./Scores";
import * as Video from "./Video";

const appReducer = combineReducers({
  notification: NotificationApi.reducer,
  loader: Loader.reducer,
  accounts: Accounts.reducer,
  scores: Scores.reducer,
  video: Video.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
