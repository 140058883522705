import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Circle = styled.div`
  background: ${(props) => props.color};
  border: 1px solid ${(props) => props.color} !important;
`;

export default class Stepper extends Component {
  constructor() {
    super();
    this.state = {
      // Completed - to add a check mark
      // Selected - to fill step with color
      // Highlighted - to make text of selected step bold
      steps: [],
    };
  }

  componentDidMount() {
    const { currentStepNumber } = this.props;

    const currentSteps = this.updateStep(currentStepNumber, this.stepState());

    this.setState({
      steps: currentSteps,
    });
  }

  componentDidUpdate(prevProps) {
    const { steps } = this.state;

    const currentSteps = this.updateStep(
      this.props.currentStepNumber,
      this.props.currentStepNumber === 0 ? this.stepState() : steps
    );

    if (prevProps.currentStepNumber !== this.props.currentStepNumber) {
      this.setState({
        steps: currentSteps,
      });
    }
  }

  stepState = () => {
    const { steps } = this.props;

    const stepCount = Array.from({ length: steps });

    const stepsState = stepCount.map((step) => {
      const stepObj = {};
      stepObj.description = step;
      stepObj.selected = false;
      stepObj.completed = false;
      return stepObj;
    });
    return stepsState;
  };

  updateStep(stepNumber, steps) {
    const { scores } = this.props;
    const newSteps = [...steps];
    let stepCounter = 0;

    // Completed - to add a check mark
    // Selected - to fill step with color
    // Highlighted - to make text of selected step bold

    while (stepCounter < newSteps.length) {
      // Current step
      if (stepCounter === stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          selected: true,
          completed: false,
        };
        stepCounter++;
      }
      // Past step
      else if (stepCounter < stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          selected: true,
          completed: true,
          isCorrect: scores[stepCounter] === "green",
        };
        stepCounter++;
      }
      // Future step
      else {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          selected: false,
          completed: false,
        };
        stepCounter++;
      }
    }

    return newSteps;
  }

  render() {
    const { direction, stepColor } = this.props;
    const { steps } = this.state;
    // const checkMark = <span>&#10003;</span>;
    // const xMark = <span>X</span>;
    const checkMark = <FontAwesomeIcon icon="check" color="white" />;
    const xMark = <FontAwesomeIcon icon="times" color="white" />;

    const stepsJSX = steps.map((step, index) => {
      let bgStyleCompleted = step.completed && !step.isCorrect ? "red" : "green";

      if (step.selected && step.completed === false) {
        bgStyleCompleted = stepColor;
      }

      if (step.selected === false && step.completed === false) {
        bgStyleCompleted = "none";
      }

      return (
        <div className="step-wrapper" key={index}>
          <Circle
            className={`step-number ${
              step.selected ? "step-number-selected" : "step-number-disabled"
            }`}
            color={bgStyleCompleted}
          >
            {step.completed
              ? bgStyleCompleted === "green"
                ? checkMark
                : xMark
              : index + 1}
          </Circle>
          {index !== steps.length - 1 && (
            <div className={`divider-line divider-line-${steps.length}`} />
          )}
        </div>
      );
    });

    return <div className={`stepper-wrapper-${direction}`}>{stepsJSX}</div>;
  }
}

Stepper.propTypes = {
  direction: PropTypes.string.isRequired,
  currentStepNumber: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
  stepColor: PropTypes.string.isRequired,
  scores: PropTypes.array,
};
