import { useState } from "react";
import PageContainer from "components/Admin/Shared/PageContainer";
import InputComponent from "components/Utils/Input";
import { Button, Col, Row } from "reactstrap";
import { func, object, string } from "prop-types";

import Certificate from "components/Certificate";

const GameSettings = ({ video = {}, onSettingChange, demoImg }) => {
  const [showCertificate, setShowCertificate] = useState(false);
  const [sampleCertColor, setSampleCertColor] = useState("gold");
  const data = video.settings;

  const onChange = (e) => {
    const params = { ...data, [e.target.name]: e.target.value };
    onSettingChange && onSettingChange(params);
  };

  const sampleCertData = {
    firstname: "Juan",
    middlename: "Perez",
    lastname: "Dela Cruz",
    yearlevel: "III",
    section: "Eagle",
    school: "The School Name",
  };

  //   const sample = {
  //     studentName_xy: "700;850",
  //     yearSection_xy: "700;910",
  //     school_xy: "700;960",
  //     shareTitle: "Check out my certificate.",
  //     shareMessage: "Check out my certificate.",
  //     gold_message: "You have achieved a perfect score.",
  //     silver_message: "You have achieved an excellent score.",
  //     bronze_message: "You have achieved a good score.",
  //     default_score_message:
  //       "You have achieved a score of {score}. Feel free to review and take the quiz again so you can become a certified Ocean Warrior! Goodluck!",
  //   };

  // console.log("COLR ", video.settings[`${sampleCertColor}_fontcolor`]);
  return (
    <PageContainer title="Settings">
      {showCertificate && (
        <Certificate
          bgImage={
            process.env.NODE_ENV === "development"
              ? `http://localhost:3000/images/certificate/gold.jpg`
              : demoImg[sampleCertColor]
          }
          isDemo
          accounts={sampleCertData}
          color={video.settings[`${sampleCertColor}_fontcolor`] || "white"}
          configs={{
            name_xy: data?.studentName_xy,
            yearSection_xy: data?.yearSection_xy,
            school_xy: data.school_xy,
          }}
          onCancel={() => setShowCertificate(false)}
        />
      )}
      <Row>
        <Col md={4}>
          <InputComponent
            label="StudentName XY"
            name="studentName_xy"
            onChange={onChange}
            value={data?.studentName_xy || ""}
          />
        </Col>
        <Col md={4}>
          <InputComponent
            label="Year & Section XY"
            name="yearSection_xy"
            onChange={onChange}
            value={data?.yearSection_xy || ""}
          />
        </Col>
        <Col md={4}>
          <InputComponent
            label="School XY"
            name="school_xy"
            onChange={onChange}
            value={data?.school_xy || ""}
          />
        </Col>
      </Row>
      <hr />
      <h4>Certificate font Color</h4>

      <Row>
        <Col md={4}>
          {" "}
          <InputComponent
            label="Gold Font Color"
            name="gold_fontcolor"
            onChange={onChange}
            value={data?.gold_fontcolor || "white"}
          />
        </Col>
        <Col md={4}>
          {" "}
          <InputComponent
            label="Silver Font Color"
            name="silver_fontcolor"
            onChange={onChange}
            value={data?.silver_fontcolor || "white"}
          />
        </Col>
        <Col md={4}>
          {" "}
          <InputComponent
            label="Bronze Font Color"
            name="bronze_fontcolor"
            onChange={onChange}
            value={data?.bronze_fontcolor || "white"}
          />
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <Button
            block
            size="sm"
            color="info"
            style={{ marginTop: "30px" }}
            onClick={() => {
              setShowCertificate(!showCertificate);
            }}
          >
            Check Sample Certificate
          </Button>
        </Col>
        <Col md={2}>
          <InputComponent
            type="select"
            label="Certificate Sample"
            name="sample_cert"
            onChange={(e) => {
              setSampleCertColor(e.target.value);
            }}
            value={sampleCertColor || "gold"}
          >
            <option value="gold">Gold</option>
            <option value="silver">Silver</option>
            <option value="bronze">Bronze</option>
          </InputComponent>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col md={6}>
          <InputComponent
            label="Share Title"
            name="shareTitle"
            onChange={onChange}
            value={data?.shareTitle || ""}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <InputComponent
            label="Share Message"
            name="shareMessage"
            onChange={onChange}
            value={data?.shareMessage || ""}
          />
        </Col>
      </Row>

      <h4>Score Messages</h4>
      <Row>
        <Col md={6}>
          <InputComponent
            label="Gold"
            name="gold_message"
            onChange={onChange}
            value={data?.gold_message || ""}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <InputComponent
            label="Silver"
            name="silver_message"
            onChange={onChange}
            value={data?.silver_message || ""}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <InputComponent
            label="Bronze"
            name="bronze_message"
            onChange={onChange}
            value={data?.bronze_message || ""}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <InputComponent
            type="textarea"
            label="Default Message"
            name="default_score_message"
            onChange={onChange}
            value={data?.default_score_message || ""}
          />
        </Col>
      </Row>
      <hr />
      <h3>Email Templates</h3>
      <Row>
        <Col md={12}>
          <InputComponent
            type="textarea"
            rows={20}
            label="Mail to send to the student after the quiz"
            name="email_templates"
            onChange={onChange}
            value={data?.email_templates || ""}
          />
          <div>{"{studentFullName}"} - FullName of the student</div>
          <div>{"{footerLogoImage}"} - Logo of easytours</div>
        </Col>
      </Row>
    </PageContainer>
  );
};

GameSettings.propTypes = {
  video: object,
  onSettingChange: func,
  demoImg: string,
};

export default GameSettings;
