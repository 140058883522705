export const APP_LOAD = "APP_LOAD";
export const REDIRECT = "REDIRECT";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const SET_NOTIFICATION_ID = "SET_NOTIFICATION_ID";
export const SET_TOKEN = "SET_TOKEN";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SET_USER = "SET_USER";
export const SET_SCORES = "SET_SCORES";
export const SET_VIDEO_DATA = "SET_VIDEO_DATA";
