import React from "react";
import { useSelector } from "react-redux";

import { Switch, Route } from "react-router";
import LoadingOverlay from "react-loading-overlay";

// import PageContainer from "./components/Shared/PageContainer";
import Default from "./components/Default";
import NotFound from "./components/Notfound";
import Admin from "components/Admin";

const Layout = () => {
  const { show } = useSelector((state) => {
    return {
      show: state.loader.show,
    };
  });

  return (
    <React.Fragment>
      <LoadingOverlay
        fadeSpeed={100}
        classNamePrefix="fullpage-overlay"
        active={show}
        spinner
        text="Please wait..."
      >
        <Switch>
          <Route exact path="/admin/:pages" component={Admin} />
          <Route path="/:urlId" component={Default} />
          <Route component={NotFound} />
        </Switch>
      </LoadingOverlay>
    </React.Fragment>
  );
};

export default Layout;
