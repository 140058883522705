import { useState } from "react";
import { array, func } from "prop-types";
import { Container, Button, ButtonGroup } from "reactstrap";
import Stepper from "../Shared/Stepper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Message from "./Message";

const Quiz = ({ onClose, data }) => {
  const [state, setState] = useState({
    currentStep: 0,
    selectedAnswer: [],
    scores: [],
    isExamEnd: false,
  });

  // const [state, setState] = useState({
  //   currentStep: 0,
  //   selectedAnswer: [],
  //   scores: Array.from({ length: 6 }).map(() => "green"),
  //   isExamEnd: true,
  // });

  const updateNext = () => {
    // eslint-disable-next-line prefer-const
    let { currentStep, selectedAnswer } = state;
    if (selectedAnswer.length - 1 !== currentStep) return;

    currentStep = currentStep + 1;

    // if (currentStep > 9) {
    if (currentStep > data.length - 1) {
      setState({ ...state, isExamEnd: true, currentStep });
      return;
    }

    setState({ ...state, currentStep });
  };

  const selectedAnswer = (e) => {
    const { selectedAnswer, currentStep, scores } = state;
    const questions = (data && data[currentStep]) || [];

    const value = +e.target.value;

    if (selectedAnswer[currentStep]) {
      selectedAnswer[currentStep] = value;
    } else {
      selectedAnswer.push(value);
    }

    if (questions.answer === selectedAnswer[currentStep]) {
      scores.push("green");
    } else {
      scores.push("red");
    }

    setState({ ...state, selectedAnswer, scores });
  };

  const tryAgain = () => {
    setState({
      ...state,
      currentStep: 0,
      selectedAnswer: [],
      scores: [],
      isExamEnd: false,
    });
  };

  const onCloseMessage = () => {
    onClose && onClose();
  };

  const { currentStep, scores, isExamEnd } = state;
  // const { data, videoUid;
  const questions = (data && data[currentStep]) || [];
  const selection = questions?.choices;
  const totalScore = scores?.filter((x) => x === "green").length;

  const questionImage = questions?.image;

  return (
    <Container
      className="h-100 d-flex justify-content-center"
      style={{
        // border: "1px solid red",
        minHeight: "calc(100vh - 100px)",
        // paddingTop:
        //   isExamEnd && totalScore >= 8 ? "calc(100vh - 55%)" : "calc(100vh - 45%)",
      }}
    >
      <div className="questionaire my-auto">
        <div className="container mt-sm-5 my-1">
          <div className="stepper-container-horizontal">
            <Stepper
              steps={data.length}
              direction="horizontal"
              currentStepNumber={currentStep}
              stepColor="orange"
              scores={scores}
            />
          </div>

          {!isExamEnd && (
            <div className="question ml-sm-1 pl-sm-3 pt-2 d-flex row">
              <div
                className="col-md-3"
                style={{
                  height: "100%",
                  justifyContent: "center",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                }}
              >
                <img src={questionImage} className="img-thumbnail" />
              </div>
              <div className="col">
                <div
                  className="h5"
                  style={{
                    fontSize: "18px",
                    marginBottom: "29px",
                  }}
                >
                  <b>{questions.questions}</b>
                </div>
                <div className="pt-sm-0 pt-3" id="options">
                  {selection &&
                    selection.map((answer, i) => {
                      return (
                        <label className="options" key={`selection-${i}`}>
                          <span
                            style={{ fontSize: "16px" }}
                            dangerouslySetInnerHTML={{ __html: answer }}
                          />
                          <input
                            type="radio"
                            name="radio"
                            key={`${i}-${currentStep}`}
                            value={i}
                            onChange={selectedAnswer}
                            style={{ display: "none" }}
                          />{" "}
                          <span className="checkmark" />{" "}
                        </label>
                      );
                    })}
                </div>
              </div>
            </div>
          )}

          {isExamEnd && (
            <Message
              totalScore={totalScore}
              onTryAgain={tryAgain}
              onClose={onCloseMessage}
            />
          )}

          {!isExamEnd && (
            <div className="d-flex align-items-center pt-3">
              <div className="ml-auto mr-sm-2">
                <ButtonGroup>
                  {!isExamEnd && (
                    <Button color="success" onClick={updateNext} size="sm">
                      Next <FontAwesomeIcon icon="long-arrow-alt-right" />
                    </Button>
                  )}

                  {/* {isExamEnd && (
                    <Button onClick={this.updateNext} size="sm">
                      <FontAwesomeIcon icon="window-close" />
                    </Button>
                  )} */}
                </ButtonGroup>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};
Quiz.propTypes = {
  data: array,
  onClose: func,
};
export default Quiz;
