/* eslint-disable no-case-declarations */
import * as appAction from "../constants/appAction.js";

const initialState = [];

export function setScores(data) {
  return (dispatch) => {
    dispatch({ type: appAction.SET_SCORES, payload: data });
  };
}

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case appAction.SET_SCORES:
      const payload = action.payload;
      return [...payload];
    default:
      return state;
  }
};
