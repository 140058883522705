import React, { useState, useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
// import Particles from "react-tsparticles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animateCSS, getCertificateColor, reSampleImage } from "helper";
import NotificationSystem from "react-notification-system";
// import moment from "moment";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavbarText,
  Container,
  ButtonGroup,
  Button,
} from "reactstrap";

import Video from "components/Video";
import Certificate from "components/Certificate";
import Quiz from "components/Quiz";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Api from "Api";
import { useParams } from "react-router-dom";
import { func } from "prop-types";
// import { setUser } from "reducers/Accounts";
// import { setScores } from "reducers/Scores";

// const maxDate = moment("2022-06-01");
// const dateNow = moment();
const buttonIsDisabled = false;

const StyledNavBar = styled(Navbar)`
  background-color: #fcb402 !important;
  height: 40px;

  @media (max-width: 768px) {
    height: unset;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    background: url("${(props) =>
      encodeURI(props.bg)}") no-repeat center center fixed;
    background-size: 100% 100%;
  }
`;

export const MainBg = styled.div``;

const VideoContainer = styled(Container)`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 0;
  ${(props) => props.isYoutube && "background-color: black;"}

  @media (max-width: 800px) {
    width: 90%;
  }

  @media (min-width: 968px) {
    width: 80%;
  }
`;

const StyledButtonGroupMenu = styled(ButtonGroup)`
  margin: 10px;
  button {
    margin-right: 20px;
  }
`;

const Main = ({ onChangeSession }) => {
  const dispatch = useDispatch();
  const params = useParams();
  // const accounts = useSelector((state) => state.accounts);
  // const videos = useSelector((state) => state.video);
  const { accounts, videos, scores } = useSelector((state) => {
    return {
      accounts: state.accounts,
      videos: state.video,
      scores: state.scores?.find((x) => x.video_id === state.video?.uid),
    };
  }, shallowEqual);

  const [isOpen, setIsOpen] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [isShowCertificate, setCertificate] = useState(false);
  const [isShowQuiz, setQuiz] = useState(false);
  const [certificateBG, setCetificateBG] = useState("");

  const toggle = () => setIsOpen(!isOpen);
  const initFontLoader = useRef();
  const notificationSystemInstance = useRef();

  useEffect(() => {
    if (accounts?.game_uid !== params.urlId && accounts?.role !== "admin") {
      onChangeSession && onChangeSession();
    }
  }, [accounts?.game_uid]);

  useEffect(() => {
    reSampleImage(initFontLoader?.current);
  }, []);

  useEffect(() => {
    animateCSS(".menu", "bounce");
  }, [isPlaying]);

  const playVideo = () => {
    animateCSS(".menu", "backOutDown").then(() => {
      setPlaying(true);
    });
  };

  const closeVideo = () => {
    if (isPlaying) {
      setPlaying(false);
    }
    animateCSS(".menu", "backInDown");
  };

  const showQuiz = (endVideo = false) => {
    if (endVideo) {
      closeVideo();
    }

    animateCSS(".menu", "backOutDown").then(() => {
      setQuiz(true);
    });
  };

  const onCloseQuiz = () => {
    if (isShowQuiz) {
      setQuiz(false);
    }
    animateCSS(".menu", "backInDown");
  };

  let certImage = {};
  const showCertificate = () => {
    if (scores?.certificate_name) {
      animateCSS(".menu", "backOutDown").then(() => {
        certImage = getCertificateColor(scores.score, videos.questions?.length);
        const bg = `https://gamified.easytours.com.ph/files/${videos.uid}/certificate/${scores?.certificate_name}`;
        setCetificateBG(bg);
        setCertificate(true);
      });
    } else {
      notificationSystemInstance.current.addNotification({
        title: "Sorry!",
        message:
          "Looks like you don't have a certificate yet. Complete the quiz to acquire it",
        level: "error",
        position: "bc",
      });
    }
  };

  const onCertificateCancel = () => {
    setCertificate(false);
    animateCSS(".menu", "backInDown");
  };

  const Logout = async () => {
    await Api.Account.Logout();
    setPlaying(false);
    setQuiz(false);
    dispatch({ type: "USER_LOGOUT" });
    window.location.reload();
  };
  const settings = videos.settings;

  return (
    <>
      <MainBg bg={isShowQuiz && videos?.bg_image}>
        <NotificationSystem ref={notificationSystemInstance} allowHTML newOnTop />

        <div
          style={{
            display: "none",
          }}
        >
          <canvas ref={initFontLoader} width="100" height="100" />
        </div>

        <StyledNavBar dark color="dark" expand="md">
          <NavbarBrand href={window.location.pathname}>
            Easytours Virtual Learning Journeys
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar />
            <NavbarText style={{ color: "white" }}>
              Welcome, {accounts?.firstname}! |{" "}
              <a href="javascript:;" onClick={Logout}>
                Logout
              </a>
            </NavbarText>
          </Collapse>
        </StyledNavBar>

        {isShowQuiz ? (
          <Quiz
            data={videos?.questions}
            onClose={onCloseQuiz}
            videoUid={videos?.uid}
          />
        ) : null}

        <Container className="d-flex justify-content-center align-items-center">
          {isPlaying ? (
            <VideoContainer
              className="mt-4"
              isYoutube={!videos?.video_link?.includes("vimeo.com")}
            >
              <div className="d-flex justify-content-end bg-warning">
                <ButtonGroup size="sm">
                  <Button onClick={closeVideo} color="warning">
                    <FontAwesomeIcon icon="window-close" color="white" />
                  </Button>
                </ButtonGroup>
              </div>
              <Video onEnd={showQuiz} videoLink={videos?.video_link} />
            </VideoContainer>
          ) : null}

          {isShowCertificate && (
            <Certificate
              configs={{
                name_xy: settings?.studentName_xy,
                yearSection_xy: settings?.yearSection_xy,
                school_xy: settings.school_xy,
              }}
              onCancel={onCertificateCancel}
              bgImage={certificateBG}
              useSaveCertificate={true}
              accounts={accounts}
              color={videos.settings[`${certImage.field}_fontcolor`] || "white"}
            />
          )}

          {isPlaying || isShowCertificate || isShowQuiz ? null : (
            <div className="navbar navbar-default fixed-bottom pb-4">
              <Container className="d-flex justify-content-center menu">
                <div>
                  <StyledButtonGroupMenu>
                    <Button
                      disabled={buttonIsDisabled}
                      color="warning"
                      onClick={() =>
                        (window.location.href =
                          "https://www.easytours.com.ph/virtual-learning-journeys/")
                      }
                    >
                      <FontAwesomeIcon icon="home" size="5x" color="white" />
                      <br />
                      Home
                    </Button>
                    <Button
                      onClick={playVideo}
                      color="warning"
                      disabled={buttonIsDisabled}
                    >
                      <FontAwesomeIcon icon="play-circle" size="5x" color="white" />
                      <br />
                      <span>Play Video</span>
                    </Button>
                    <Button
                      color="warning"
                      onClick={showQuiz}
                      disabled={buttonIsDisabled}
                    >
                      <FontAwesomeIcon
                        icon="edit"
                        size="5x"
                        color="white"
                        style={{ verticalAlign: "middle" }}
                      />
                      <br />
                      <span>Take Quiz</span>
                    </Button>
                    <Button
                      color="warning"
                      onClick={showCertificate}
                      disabled={buttonIsDisabled}
                    >
                      <FontAwesomeIcon icon="certificate" size="5x" color="white" />
                      <br />
                      Certificate
                    </Button>
                  </StyledButtonGroupMenu>
                </div>
              </Container>
            </div>
          )}
        </Container>
      </MainBg>
      <GlobalStyle bg={isShowQuiz ? videos?.registration_image : videos?.bg_image} />
    </>
  );
};
Main.propTypes = {
  onChangeSession: func,
};
export default Main;
