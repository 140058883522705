import { useEffect, useState, useRef } from "react";
import PageContainer from "components/Admin/Shared/PageContainer";
import Confirm from "components/Utils/Confirm";
import toastr from "toaster";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Row,
  Col,
} from "reactstrap";
import { FaTrashAlt, FaEdit, FaEye } from "react-icons/fa";
// import { array, func, string } from "prop-types";
import Api from "Api";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
// import { useHistory } from "react-router-dom";
import InputComponent from "components/Utils/Input";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import styled from "styled-components";

const TableWrapper = styled.div`
  .expand-cell-header {
    cursor: pointer;
  }

  td.expand-cell {
    text-align: left;
    cursor: pointer;
  }
`;

const Userslist = () => {
  const formRef = useRef(null);

  // const history = useHistory();
  const [users, setUsers] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [searchString, setSearchString] = useState("");

  const [loaded, setLoaded] = useState(false);
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const getAllUsers = async () => {
    const response = await Api.Users.getUserlist();
    setUsers(response.result);
    setLoaded(true);
  };

  const getAllGames = async () => {
    const response = await Api.Games.getAll();
    setGames(response.result);
    setLoaded(true);
  };

  const deleteUser = async (id) => {
    await Api.Users.deleteUser(id);
    getAllUsers();
  };

  useEffect(() => {
    getAllUsers();
    getAllGames();
  }, []);

  useEffect(() => {
    if (selectedGame === "") {
      getAllUsers();
    }
  }, [selectedGame]);

  const columns = [
    {
      dataField: "firstname",
      text: "Name",
      sort: true,
      // headerStyle: () => {
      //   return { width: "250px" };
      // },
      formatter: (cell, row) => {
        return row.firstname + " " + row.lastname;
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "school",
      text: "School",
      sort: true,
    },
    {
      dataField: "yearlevel",
      text: "Year Level",
      align: "center",
      sort: true,
      headerStyle: () => {
        return { width: "140px", textAlign: "center" };
      },
    },
    {
      dataField: "section",
      text: "Section",
      align: "center",
      sort: true,
      headerStyle: () => {
        return { width: "140px", textAlign: "center" };
      },
    },

    {
      dataField: "uid",
      text: "",
      headerStyle: () => {
        return { width: "100px", textAlign: "center" };
      },
      align: "center",
      isDummyField: true,
      formatter: (cell, row) => {
        return (
          <ButtonGroup size="sm">
            <Button
              color="info"
              onClick={() => {
                setFormData(row);
                setShowModal(true);
              }}
            >
              <FaEdit />
            </Button>
            <Confirm
              title="Confirm Removal"
              body="Are you sure want to delete this item?"
              onConfirm={() => {
                deleteUser(row.id);
              }}
            >
              <Button color="danger">
                <FaTrashAlt />
              </Button>
            </Confirm>
          </ButtonGroup>
        );
      },
      isDummy: true,
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div
        className="bg-primary"
        style={{
          color: "white",
          height: "30px",
          paddingTop: "5px",
          paddingLeft: "10px",
          paddingRight: "70px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          Registered in: <strong>{row.gamified}</strong>
        </div>
        <div>
          <strong>Score: {row.score || 0}</strong>
        </div>
      </div>
    ),
    showExpandColumn: true,
  };

  const filteredData = () => {
    let userData = selectedGame
      ? users.filter((x) => x.game_uid === selectedGame)
      : users;

    if (searchString !== "") {
      userData = userData.filter((x) => {
        return (
          `${x.firstname}`.toLowerCase().includes(searchString.toLowerCase()) ||
          `${x.lastname}`.toLowerCase().includes(searchString.toLowerCase()) ||
          `${x.email}`.toLowerCase().includes(searchString.toLowerCase()) ||
          `${x.school}`.toLowerCase().includes(searchString.toLowerCase()) ||
          `${x.yearlevel}`.toLowerCase().includes(searchString.toLowerCase()) ||
          `${x.section}`.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    }

    return userData;
  };

  const toggleModal = () => {
    setShowPassword(false);
    setShowModal(!showModal);
    setFormData({});
  };
  const onSubmit = () => {
    formRef.current.submit();
  };

  const handleValidSubmit = async (event, values) => {
    const params = {
      ...values,
      id: formData.id,
    };
    if (values?.change_password) {
      params.password = btoa(values.change_password);
    }
    await Api.Users.updateUser(params);
    toastr.success("User updated successfully");
    toggleModal();
    getAllUsers();
  };

  return (
    <PageContainer title="Users List">
      <Row>
        <Col md={4}>
          <InputComponent
            type="select"
            label="Filter by gamified"
            onChange={(e) => {
              setSelectedGame(e.target.value);
              setSearchString("");
            }}
            value={selectedGame || ""}
          >
            <option value="">All</option>
            {games.map((game) => {
              return (
                <option key={game.uid} value={game.uid}>
                  {game.name}
                </option>
              );
            })}
          </InputComponent>
        </Col>
        <Col md={4}>
          <InputComponent
            name="search"
            label="Search"
            placeholder="Search anything..."
            value={searchString || ""}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </Col>
      </Row>
      {loaded ? (
        <TableWrapper>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={filteredData()}
            columns={columns}
            bodyStyle={{ wordBreak: "break-all" }}
            pagination={paginationFactory({ page: 1, showTotal: true })}
            expandRow={expandRow}
          />
        </TableWrapper>
      ) : (
        <div>Loading. Please wait...</div>
      )}

      <Modal isOpen={showModal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Modify User</ModalHeader>
        <ModalBody>
          <AvForm
            model={formData}
            ref={formRef}
            onValidSubmit={handleValidSubmit}
            onInvalidSubmit={(i) => console.log("INVALID, ", i)}
          >
            <Row>
              <Col md={4}>
                {" "}
                <AvField name="firstname" label="First Name" required />
              </Col>
              <Col md={4}>
                <AvField name="middlename" label="Middle Name" />
              </Col>
              <Col md={4}>
                <AvField name="lastname" label="Last Name" required />
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <AvField name="school" label="School" required />
              </Col>
              <Col md={3}>
                <AvField name="yearlevel" label="Year Level" type="select" required>
                  <option value={""}>Select</option>
                  <option value={"Kinder"}>Kinder</option>
                  {Array.from({ length: 12 }).map((v, i) => {
                    const value = `Grade ${i + 1}`;
                    return (
                      <option key={`yr-${i}`} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </AvField>
              </Col>
              <Col md={4}>
                <AvField name="section" label="Section" required />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <AvField name="email" label="Email" disabled />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                Current Password:
                <Button
                  size="sm"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {!showPassword ? (
                    <>
                      {" "}
                      <span style={{ textAlign: "center" }}>
                        **************** <FaEye />
                      </span>
                    </>
                  ) : (
                    atob(formData.password)
                  )}
                </Button>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={6}>
                <AvField
                  name="change_password"
                  label="Change Password"
                  errorMessage="Password minimum of 6 character"
                  validate={{ minLength: { value: 6 } }}
                  autoComplete="off"
                />
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmit}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </PageContainer>
  );
};

export default Userslist;
