/* eslint-disable no-case-declarations */
import * as appAction from "../constants/appAction.js";
import { capitalize } from "../helper.js";

const initialState = { notificationId: 0 };

export const actionCreators = {
  showNotification: (params) => (dispatch) => {
    dispatch({ type: appAction.SHOW_NOTIFICATION, payload: params });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case appAction.SHOW_NOTIFICATION:
      const payload = action.payload;
      return {
        ...state,
        message: payload.message,
        type: payload.type,
        title: payload && payload.title ? payload.title : capitalize(payload.type),
        notificationId: Math.random(),
      };
    default:
      return state;
  }
};
