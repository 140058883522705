import { useEffect, useRef, useState } from "react";
import PageContainer from "components/Admin/Shared/PageContainer";
import AssetsUploader from "components/Shared/AssetsUploader";
import InputComponent from "components/Utils/Input";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Row,
  Col,
} from "reactstrap";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import { array, func, string } from "prop-types";
import Api from "Api";
import Confirm from "components/Utils/Confirm";

const Questions = ({ data = [], gameId = "", onSave }) => {
  const [modal, setModal] = useState(false);
  const formRef = useRef(null);
  const [formData, setFormData] = useState({});
  const [choices, setChoices] = useState([]);

  const toggle = () => setModal(!modal);

  const addChoices = () => {
    setChoices([...choices, ""]);
  };

  const removeOption = (index) => {
    const newChoices = [...choices];
    newChoices.splice(index, 1);
    setChoices(newChoices);
  };

  const onChangeOptions = (e, index) => {
    const newChoices = [...choices];
    newChoices[index] = e.target.value;
    setChoices(newChoices);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const setNewImage = (file) => {
    setFormData({
      ...formData,
      new_image: file.base64String,
      new_filename: file.new_fileName,
    });
  };

  const onSubmit = async () => {
    const payload = {
      ...formData,
      answer: formData?.answer || 0,
      game_id: gameId,
      choices: JSON.stringify(choices),
    };

    const response = await Api.Games.saveQuestion(payload);
    onSave && onSave(response);
    setModal(false);
  };

  const onEdit = (question) => {
    setFormData(question);
    setChoices(question.choices);
    setModal(true);
  };

  const deleteQuestion = async (id) => {
    await Api.Games.delete_question(id);
    setModal(false);
    onSave && onSave();
  };

  useEffect(() => {
    if (!modal) setChoices([]);
  }, [modal]);

  return (
    <PageContainer
      fontSize="14px"
      menuAction={
        <ButtonGroup size="sm">
          <Button
            color="primary"
            disabled={data.length === 10}
            onClick={() => {
              setFormData({});
              setChoices([]);
              setModal(true);
            }}
          >
            Add questions
          </Button>
        </ButtonGroup>
      }
    >
      {data.length ? (
        data.map((item, i) => {
          return (
            <div
              className="d-flex text-muted pt-3"
              key={i}
              style={{ fontSize: "15px" }}
            >
              <div className="mr-3">
                <img
                  src={
                    item.image ? item.image : "https://via.placeholder.com/150x200"
                  }
                  alt="Gamified"
                  className="img-fluid"
                />
              </div>

              <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                <div className="d-flex justify-content-between">
                  <strong className="text-gray-dark">{item.questions}</strong>
                  <ButtonGroup size="sm">
                    <Button color="info" onClick={() => onEdit(item)}>
                      <FaEdit />
                    </Button>
                    <Confirm
                      title="Confirm Removal"
                      body="Are you sure want to delete this item?"
                      onConfirm={() => {
                        deleteQuestion(item.id);
                      }}
                    >
                      <Button color="danger">
                        <FaTrashAlt />
                      </Button>
                    </Confirm>
                  </ButtonGroup>
                </div>

                <Row>
                  <Col md={6}>
                    <div className="d-flex justify-content-between font-weight-bold pt-2">
                      Choices
                    </div>

                    {item.choices.map((choice, index) => {
                      return (
                        <li className="d-block" key={index}>
                          - <span dangerouslySetInnerHTML={{ __html: choice }} />
                        </li>
                      );
                    })}
                  </Col>
                  <Col md={6}>
                    <span className="d-block pt-3">
                      <strong>Answer</strong>: {item.choices[item.answer]}
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })
      ) : (
        <div>No Questions found...</div>
      )}

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Add Questions</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <div className="d-flex flex-column justify-content-between font-weight-bold pt-2">
                <img
                  src={
                    formData?.image
                      ? formData.image
                      : "https://via.placeholder.com/150x200"
                  }
                  alt="Gamified"
                  className="img-fluid"
                />
                <AssetsUploader
                  title="Click to Change Image"
                  onBrowse={setNewImage}
                  showImage={false}
                />
              </div>
            </Col>
            <Col md={8}>
              <AvForm
                ref={formRef}
                onValidSubmit={(f) => console.log("VALID, ", f)}
                onInvalidSubmit={(i) => console.log("INVALID, ", i)}
              >
                <AvField
                  name="questions"
                  label="Question"
                  type="textarea"
                  value={formData?.questions || ""}
                  onChange={onChange}
                />

                <div className="border-bottom d-flex flex-row-reverse mb-2">
                  <Button
                    size="sm"
                    color="primary"
                    className="mb-2"
                    onClick={addChoices}
                  >
                    + Add option
                  </Button>
                </div>
                {choices.length ? (
                  choices.map((ch, i) => {
                    return (
                      <Row key={i}>
                        <Col md={10}>
                          <AvField
                            name="choices"
                            type="text"
                            autoComplete="off"
                            value={ch || ""}
                            onChange={(e) => onChangeOptions(e, i)}
                          />
                        </Col>
                        <Col md={2}>
                          <ButtonGroup size="sm" style={{ marginTop: 5 }}>
                            <Button color="danger" onClick={() => removeOption(i)}>
                              <FaTrashAlt />
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <div className="mb-4">No options declared...</div>
                )}
                <div className="border-bottom" />

                <InputComponent
                  name="answer"
                  label="Answer"
                  type="select"
                  value={+formData?.answer}
                  onChange={onChange}
                >
                  <option value={100}>Select an answer</option>
                  {choices.map((c, i) => {
                    return (
                      <option key={i} value={+i}>
                        {c}
                      </option>
                    );
                  })}
                </InputComponent>
              </AvForm>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmit}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </PageContainer>
  );
};

Questions.propTypes = {
  data: array,
  gameId: string,
  onSave: func,
};

export default Questions;
