import React, { useState, createRef } from "react";
import Dropzone from "react-dropzone";
import { getBase64 } from "helper";
import styled from "styled-components";
import { Button, Row, Col } from "reactstrap";
import toaster from "toaster";
import { bool, func, string } from "prop-types";

const UploadWrapper = styled.div`
  display: flex;
  font-size: 12px;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  border-width: 1px;
  border-radius: 2;
  border-color: ${(props) => (props.hasFiles ? "red" : "black")};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  transition: border 0.3s ease-in-out;
`;

const AssetsUploader = ({ onBrowse, name, currentImage = "", showImage = true }) => {
  const [files, setFiles] = useState({});
  const dropzoneRef = createRef();

  const onDrop = async (acceptedFiles, err) => {
    const file = acceptedFiles[0];
    console.log("FILE ", err);
    if (file) {
      setFiles(file);
      const base64String = await getBase64(file);
      onBrowse && onBrowse({ base64String, new_fileName: file.name });
    } else {
      toaster.error("Files is too large. Maximum size is 3MB");
    }
  };
  // clean up
  return (
    <UploadWrapper hasFiles={Object.keys(files).length > 0}>
      {/* // Disable click and keydown behavior on the */}
      <Dropzone
        ref={dropzoneRef}
        noClick
        noKeyboard
        name={name}
        onDrop={onDrop}
        maxSize={3145728}
        maxS
        accept="image/jpg, image/png, image/jpeg"
      >
        {({ getRootProps, getInputProps, acceptedFiles }) => {
          return (
            <div className="container">
              <div {...getRootProps({ className: "dropzone" })}>
                <Row>
                  <Col md={showImage ? 6 : 12}>
                    <input {...getInputProps()} />
                    <Button
                      type="button"
                      onClick={() => {
                        if (dropzoneRef.current) {
                          dropzoneRef.current.open();
                        }
                      }}
                    >
                      Browse File
                    </Button>
                    <br />
                    {acceptedFiles.length ? "1 file to upload" : ""}
                  </Col>
                  {currentImage && (
                    <Col md={6}>
                      <img src={currentImage} className="img-fluid" alt="Gamified" />
                    </Col>
                  )}
                </Row>
              </div>
              {/* <aside>
                {acceptedFiles.map((file) => (
                  <li key={file.path}>
                    {file.path} - {file.size} bytes
                  </li>
                ))}
              </aside> */}
            </div>
          );
        }}
      </Dropzone>
    </UploadWrapper>
  );
};

AssetsUploader.propTypes = {
  onBrowse: func,
  name: string,
  currentImage: string,
  showImage: bool,
};

export default AssetsUploader;
