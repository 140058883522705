const { default: PageContainer } = require("components/Admin/Shared/PageContainer");
const { default: InputComponent } = require("components/Utils/Input");
const { Row, Col, Button, Alert } = require("reactstrap");
import { useState } from "react";
import Api from "Api";
import { useHistory } from "react-router-dom";

const LoginAdmin = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(false);
  const [isRequesting, setRequesting] = useState(false);
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    const payload = { ...formData, game_uid: "ADMIN" };
    if (!payload.email || !payload.password) return;
    setRequesting(true);
    const response = await Api.Account.LoginAdmin(payload);

    if (response.result === "error") {
      setError(true);
    } else {
      history.push("/admin/games-list");
      window.location.reload();
    }
    setRequesting(false);
  };
  return (
    <PageContainer title="Admin Login" isLogin>
      {error && (
        <Alert color="danger">
          Error. Username or Password is not valid for admin
        </Alert>
      )}

      <Row>
        <Col md={4}>
          <InputComponent
            label="Username"
            name="email"
            type="text"
            value={formData?.email || ""}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <InputComponent
            label="Password"
            name="password"
            type="password"
            value={formData?.password || ""}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Button onClick={onSubmit} color="info" disabled={isRequesting}>
            {isRequesting ? "Please wait..." : "Login"}
          </Button>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default LoginAdmin;
