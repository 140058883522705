import superagentPromise from "superagent-promise";
import localforage from "localforage";
import _superagent from "superagent";
import config from "./webconfig";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = config.apiURL;

const responseBody = (res) => res.body;

let token = null;

const tokenPlugin = (req) => {
  if (token) {
    req.set("Authorization", `Bearer ${token}`);
  }
};
const unauthorizedRedirect = (req) => {
  req.on("response", (res) => {
    if (res.status === 401) {
      // store.dispatch({type: LOGOUT });
      window.location.reload();
    }
  });
};

const Request = {
  del: (url) => {
    return superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .use(unauthorizedRedirect)
      .then(responseBody);
  },
  get: (url, params = {}) => {
    return superagent
      .get(`${API_ROOT}${url}`)
      .query(params)
      .use(tokenPlugin)
      .withCredentials()
      .use(unauthorizedRedirect)
      .then(responseBody);
  },
  put: (url, body) => {
    return superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .use(unauthorizedRedirect)
      .then(responseBody);
  },
  post: (url, body) => {
    return superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .withCredentials()
      .use(unauthorizedRedirect)
      .ok((res) => res.status < 500)
      .then(responseBody);
  },
};

const AppStorage = {
  setItem: (key, val) => localforage.setItem(key, val),
  getItem: (key) => localforage.getItem(key),
  removeItem: (key) => localforage.removeItem(key),
  clearStorage: () => localforage.clear(),
};

const Account = {
  Register: (params) => Request.post("/accounts/register", params),
  CheckSession: (videoId = "") => Request.get("/accounts/session", { videoId }),
  Login: (data) => Request.post("/accounts/login", data),
  LoginAdmin: (data) => Request.post("/accounts/superlogin", data),
  Logout: () => Request.get("/accounts/logout"),
};

const Files = {
  CertificatePath: () => Request.get("/accounts/filespath"),
};

const Users = {
  saveCertificate: (params) => Request.post("/users/certificate", params),
  getUserlist: (params = {}) => Request.get("/users/listuser", params),
  deleteUser: (id) => Request.get(`/users/delete`, { id }),
  updateUser: (params) => Request.post("/users/updateuser", params),
};

const Games = {
  save: (params) => Request.post("/games/save", params),
  getAll: () => Request.get("/games/all"),
  saveQuestion: (params) => Request.post("/games/savequestion", params),
  delete_question: (id) => Request.post(`/games/delete_question`, { id }),
  get: (question_uid) => Request.get("/games/get_games", { question_uid }),
  deleteGames: (id) => Request.get("/games/delete", { game_id: id }),
};

export default {
  setToken: (_token) => {
    token = _token;
  },
  AppStorage,
  Request,
  Account,
  Users,
  Files,
  Games,
};
