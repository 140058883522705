import React from "react";
import { func, number } from "prop-types";
import { Button, ButtonGroup } from "reactstrap";
import Certificate from "components/Certificate";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Api from "Api";
import { setScores } from "reducers/Scores";

const Message = ({ totalScore = 0, onTryAgain, onClose }) => {
  const dispatch = useDispatch();
  const { accounts, video } = useSelector((state) => {
    return {
      accounts: state.accounts,
      video: state.video,
    };
  }, shallowEqual);

  const GOLD = video?.questions?.length || 10;
  const SILVER = GOLD - 1;
  const BRONZE = GOLD - 2;

  const videoSettings = video.settings;

  let message = "";
  let headingTitle = "Well done!";
  let image = "silver";

  switch (totalScore) {
    case GOLD: {
      message = videoSettings?.gold_message || "You have achieved a perfect score.";
      image = "gold";
      break;
    }
    case SILVER: {
      message =
        videoSettings?.silver_message || "You have achieved an excellent score.";
      image = "silver";
      break;
    }
    case BRONZE: {
      message = videoSettings?.bronze_message || "You have achieved a good score.";
      image = "bronze";
      break;
    }
    default: {
      message =
        videoSettings?.default_score_message?.split("{score}").join(totalScore) ||
        `You have achieved a score of ${totalScore}. Feel free to review and take the quiz again! Goodluck!`;
      if (totalScore > 5) {
        headingTitle = "Almost there!";
      } else {
        headingTitle = "Try Again!";
      }
    }
  }

  const saveCertificate = async (imgData) => {
    const newScore = await Api.Users.saveCertificate({
      photo: imgData,
      score: totalScore,
      videoId: video.uid,
    });
    dispatch(setScores(newScore));
  };

  return (
    <div className="alert alert-success" role="alert" style={{ width: "100%" }}>
      <h4 className="alert-heading" style={{ fontWeight: "bold" }}>
        {headingTitle}
      </h4>
      <p>{message}</p>
      <hr />
      {totalScore >= BRONZE && (
        <p className="mb-0">
          <Certificate
            inLine
            configs={{
              name_xy: videoSettings?.studentName_xy,
              yearSection_xy: videoSettings?.yearSection_xy,
              school_xy: videoSettings.school_xy,
            }}
            bgImage={video[image]}
            color={video.settings[`${image}_fontcolor`] || "white"}
            onCancel={onClose}
            accounts={accounts}
            onImageCreated={saveCertificate}
          />
        </p>
      )}

      {totalScore < BRONZE && (
        <div className="text-right">
          <ButtonGroup>
            <Button color="success" onClick={onTryAgain}>
              Try Again
            </Button>
            <Button onClick={onClose}>Close</Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

Message.propTypes = {
  totalScore: number,
  onTryAgain: func,
  onClose: func,
};
export default Message;
