import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        Data Privacy Act of 2012 or formally known as Republic Act 10173 of the
        Republic of the Philippines. This Act was passed and promulgated with the
        title: An Act Protecting Individual Personal Information in Information and
        Communications Systems in the Government and the Private Sector, Creating for
        this Purpose a National Privacy Commission, and for Other Purposes. It is the
        law or “policy of the State to protect the fundamental human right of
        privacy, of communication while ensuring the free flow of information to
        promote innovation and growth. The State recognizes the vital role of
        information and communications technology in nation-building and its inherent
        obligation to ensure that personal information in information and
        communications systems in the government and in the private sector are
        secured and protected.” (Sec. 2)
      </div>
    );
  }
}
