import { node, string } from "prop-types";

const PageContainer = ({ children, title = "", menuAction, fontSize = "13px" }) => {
  return (
    <div
      className="my-3 p-3 bg-body rounded shadow-sm pb-5"
      style={{ fontSize: fontSize }}
    >
      {title || menuAction ? (
        <div className="border-bottom pb-2 mb-0 text-bold d-flex justify-content-between">
          <div>{title}</div>
          {menuAction && <div>{menuAction}</div>}
        </div>
      ) : null}

      <div className="pt-3">{children}</div>
    </div>
  );
};

PageContainer.propTypes = {
  children: node,
  title: string,
  menuAction: node,
  fontSize: string,
};

export default PageContainer;
