import * as appAction from "../constants/appAction.js";

const initialState = { show: false };

export const showLoader =
  (state = true) =>
  (dispatch) => {
    dispatch({ type: appAction.SHOW_LOADER, payload: state });
  };

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case appAction.SHOW_LOADER:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
};
