import React, { Component, createRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { bool, func, object, string } from "prop-types";
import Share from "components/Shared/Share";
import { getCertTemplate } from "helper";

export default class Certificate extends Component {
  state = {
    modalOpen: false,
    imgSrc: "",
  };
  componentDidMount() {
    this.drawImage();
  }

  canvasId = createRef();

  drawImage = () => {
    const { bgImage, accounts, configs, color, useSaveCertificate } = this.props;
    const canvas = this.canvasId.current;
    const context = canvas.getContext("2d");
    const fontColor = color || "white";

    if (useSaveCertificate) {
      this.setState({ modalOpen: true, imgSrc: bgImage });
    } else {
      const imageObj = new Image();
      imageObj.crossOrigin = "anonymous";

      imageObj.src = getCertTemplate(bgImage);

      imageObj.onload = () => {
        const getVals = (v, defaultVal) => {
          return v ? v : defaultVal;
        };
        context.drawImage(imageObj, 0, 0, 2048, 1583);
        context.textAlign = "center";
        context.font = "64pt 'Fredoka One', cursive";
        // context.fillStyle = "#fcfcfc";
        context.fillStyle = fontColor;

        const fullName = [
          accounts.firstname,
          accounts.middlename,
          accounts.lastname,
        ].join(" ");

        const name_xy = configs?.name_xy.trim().split(";");
        // context.fillText(fullName, 700, 850);
        context.fillText(
          fullName,
          getVals(name_xy[0], 700),
          getVals(name_xy[1]),
          850
        );

        context.font = "bold 30pt 'Montserrat', sans-serif";
        const yearSection = `${accounts.yearlevel} - ${accounts.section}`;

        const yearSection_xy = configs?.yearSection_xy.trim().split(";");
        // context.fillText(yearSection, 700, 910);
        context.fillText(
          yearSection,
          getVals(yearSection_xy[0], 700),
          getVals(yearSection_xy[1], 910)
        );

        const school_xy = configs?.school_xy.trim().split(";");
        // context.fillText(accounts.school, 700, 960);
        context.fillText(
          accounts.school,
          getVals(school_xy[0], 700),
          getVals(school_xy[1], 960)
        );

        const imgData = canvas.toDataURL("image/png");

        this.setState({ modalOpen: true, imgSrc: imgData });
        this.props.onImageCreated && this.props.onImageCreated(imgData);
      };
    }
  };

  download = () => {
    const { imgSrc } = this.state;
    const link = document.createElement("a");
    link.download = "Easytours Virtual Learning Journey Certificate.png";
    link.href = imgSrc;
    link.click();
  };
  closeModal = () => {
    this.setState({ modalOpen: false });
    this.props.onCancel();
  };
  render() {
    const { inLine, isDemo } = this.props;
    const { imgSrc } = this.state;

    return (
      <>
        <div style={{ fontFamily: "'Fredoka One', cursive", display: "none" }} />

        <canvas
          ref={this.canvasId}
          id="idCanvas"
          width="2048"
          height="1583"
          style={{ display: "none" }}
        />
        {inLine ? (
          <>
            <img id="canvasImg" className="img-fluid" src={imgSrc} />
            <div className="d-flex d-flex justify-content-between  pt-2">
              <div>
                <Share />
              </div>
              <div>
                <Button color="success" onClick={this.download}>
                  Download
                </Button>{" "}
                <Button color="secondary" onClick={() => this.closeModal()}>
                  Close
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div style={{ marginTop: !isDemo && 300 }}>
            <Modal isOpen={this.state.modalOpen} size="lg">
              <ModalHeader>Certificate of Completion</ModalHeader>
              <ModalBody>
                <img
                  id="canvasImg"
                  className="img-fluid"
                  src={imgSrc}
                  crossOrigin="anonymous"
                />
              </ModalBody>
              <ModalFooter
                style={!isDemo ? { justifyContent: "space-between" } : {}}
              >
                {!isDemo && <Share />}
                <div>
                  <Button color="primary" onClick={this.download}>
                    Download
                  </Button>{" "}
                  <Button color="secondary" onClick={() => this.closeModal()}>
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          </div>
        )}
      </>
    );
  }
}
Certificate.propTypes = {
  onCancel: func,
  inLine: bool,
  isDemo: bool,
  bgImage: string,
  accounts: object,
  onImageCreated: func,
  configs: object,
  color: string,
  useSaveCertificate: bool,
};

Certificate.defaultProps = {
  useSaveCertificate: false,
};
