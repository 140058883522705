import React, { Component } from "react";
import { createGlobalStyle } from "styled-components";

import {
  Container,
  Alert,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Input from "components/Utils/Input";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { MainBg } from "../Main";
import { func, string, object } from "prop-types";
import { setUser } from "reducers/Accounts";
import { setScores } from "reducers/Scores";
import { setVideo } from "reducers/Video";
import Api from "Api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isNil } from "lodash";
import PrivacyPolicy from "./PrivacyPolicy";
import toaster from "toaster";

const GlobalStyle = createGlobalStyle`
  body {
    background: url("${(props) =>
      encodeURI(props.bg)}") no-repeat center center fixed;
    background-size: cover;
  }
`;

class Register extends Component {
  state = {
    showLogin: true,
    showError: false,
    isPosting: false,
    errorText: "",
    formData: {},
    showModal: false,
  };
  async componentDidMount() {
    const rememberLoginData = await Api.AppStorage.getItem("LOGGED_IN_DATA");
    if (rememberLoginData) {
      this.setState({
        formData: JSON.parse(rememberLoginData),
      });
    }
  }
  handleSubmit = async (e, values) => {
    this.setState({ showError: false, isPosting: true });
    const payload = { ...values, game_uid: this.props.gameUid };

    const response = await Api.Account.Register(payload);
    if (response.result === "error") {
      this.setState({
        showError: true,
        isPosting: false,
        errorText: response.message,
      });
      return;
    } else {
      // this.props.setUser(response.userData.profile);
      // this.props.setScores(response.userData.scores);
      // this.props.onSuccess();
      toaster.success("Registration Successful");
      this.setState({ showLogin: true, errorText: "" });
    }
  };

  showLogin = (e) => {
    e.preventDefault();
    this.setState({ showLogin: true, errorText: "" });
  };

  onChange = (e) => {
    const { formData } = this.state;
    const name = e.target.name;
    const value = e.target.value;

    formData[name] = e.target.type === "checkbox" ? e.target.checked : value;

    this.setState({ formData });
  };

  handleLogin = async () => {
    const { formData } = this.state;
    if (isNil(formData?.email) || isNil(formData?.password)) return;

    if (formData.remember) {
      Api.AppStorage.setItem("LOGGED_IN_DATA", JSON.stringify(formData));
    } else {
      Api.AppStorage.removeItem("LOGGED_IN_DATA");
    }

    const payload = { ...formData, game_uid: this.props.gameUid };

    const response = await Api.Account.Login(payload);
    let errorText = "";

    if (response.result === "error") {
      errorText = response.message;
      this.setState({ errorText });
      return;
    } else {
      window.location.reload();
      // const video = await Api.Games.get(this.props.gameUid);
      // this.props.setVideo(video);

      // this.props.setUser(response.userData?.profile);
      // this.props.setScores(response.userData?.scores);
      // this.props.onSuccess();
    }
  };

  showModal = (what) => {
    this.setState({ showModal: true, textOf: what });
  };

  render() {
    const { videoData } = this.props;

    const { showLogin, errorText, showError, isPosting, formData } = this.state;
    // const model = {
    //   firstname: "Raymund",
    //   middlename: "R",
    //   lastname: "Alocilja",
    //   email: "mr.mon21@gmail.com",
    //   password: "123456",
    //   confirmPassword: "123456",
    //   school: "Technological Institute of the Philippines",
    //   yearlevel: "5",
    //   section: "Eagle",
    // };
    const model = {};
    return (
      <MainBg>
        <Container
          style={{
            marginTop: showLogin && "calc(100vh - 40%)",
          }}
        >
          {!showLogin && (
            <div className="signup-form">
              <AvForm onValidSubmit={this.handleSubmit} model={model}>
                <h2>Create Your Account</h2>
                <p className="text-center">{`It's quick and free of charge.`}</p>
                <div className="text-center lead">
                  Already have an account?{" "}
                  <a href="javascript:;" onClick={this.showLogin}>
                    Login here.
                  </a>
                </div>
                <Row>
                  <Col md={8}>
                    <AvField
                      name="firstname"
                      label="First Name"
                      errorMessage="Invalid Name"
                      minLength={2}
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <AvField name="middlename" label="Middle Name" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <AvField
                      name="lastname"
                      label="Last Name"
                      errorMessage="Invalid Last Name"
                      required
                      minLength={2}
                    />
                  </Col>
                </Row>
                <AvField name="email" label="Email" required type="email" />
                <AvField
                  name="password"
                  label="Password"
                  type="password"
                  errorMessage="Password minimum of 6 character"
                  validate={{ required: { value: true }, minLength: { value: 6 } }}
                />
                <AvField
                  type="password"
                  errorMessage="Password confirmation not match"
                  name="confirmPassword"
                  label="Confirm Password"
                  validate={{ match: { value: "password" } }}
                />
                <AvField name="school" label="School" required />
                <Row>
                  <Col md={4}>
                    <AvField
                      name="yearlevel"
                      label="Year Level"
                      type="select"
                      required
                    >
                      <option value={""}>Select</option>
                      <option value={"Kindergarten"}>Kindergarten</option>
                      {Array.from({ length: 12 }).map((v, i) => {
                        const value = `Grade ${i + 1}`;
                        return (
                          <option key={`yr-${i}`} value={value}>
                            {value}
                          </option>
                        );
                      })}
                    </AvField>
                  </Col>
                  <Col md={8}>
                    <AvField name="section" label="Section" required />
                  </Col>
                </Row>
                {showError && <Alert color="danger">{errorText}</Alert>}
                <div className="form-group">
                  <Button block color="primary" disabled={isPosting}>
                    Sign Up
                  </Button>
                </div>
                <p className="small text-center">
                  By clicking on the Sign Up button, you agree to our <br />
                  <a href="javascript:;">Terms &amp; Conditions</a>, and{" "}
                  <a href="javascript:;" onClick={() => this.showModal("privacy")}>
                    Privacy Policy
                  </a>
                  .
                </p>
              </AvForm>
            </div>
          )}
          {showLogin && (
            <div className="d-flex justify-content-center">
              <Card style={{ width: 400 }}>
                <CardHeader>Sign-in</CardHeader>

                <CardBody style={{ paddingTop: "30px" }}>
                  {errorText && <Alert color="danger">{errorText}</Alert>}

                  <Input
                    name="email"
                    label=""
                    placeholder="Email"
                    style={{ width: "100%", marginBottom: "25px" }}
                    value={formData?.email || ""}
                    onChange={this.onChange}
                  />
                  <Input
                    name="password"
                    type="password"
                    placeholder="Password"
                    label=""
                    style={{ width: "100%" }}
                    value={formData?.password || ""}
                    onChange={this.onChange}
                  />

                  <div className="input-group">
                    <div className="checkbox">
                      <label>
                        <input
                          id="login-remember"
                          type="checkbox"
                          name="remember"
                          value="1"
                          checked={formData?.remember || false}
                          onChange={this.onChange}
                        />{" "}
                        Remember me
                      </label>
                    </div>
                  </div>

                  <div style={{ marginTop: "10px" }} className="form-group">
                    <Button block color="success" onClick={this.handleLogin}>
                      Login
                    </Button>
                  </div>

                  <div
                    style={{
                      borderTop: "1px solid #888",
                      paddingTop: "15px",
                      fontSize: "85%",
                    }}
                  >
                    {`Don't have an account? `}
                    <a
                      href="javascript:;"
                      onClick={() =>
                        this.setState({ showLogin: false, errorText: "" })
                      }
                    >
                      Sign Up Here
                    </a>
                  </div>
                </CardBody>
              </Card>
            </div>
          )}
        </Container>
        <GlobalStyle bg={videoData?.registration_image} />

        <Modal isOpen={this.state.showModal}>
          <ModalHeader>
            {this.state.textOf === "privacy" ? "Privacy Policy" : null}
          </ModalHeader>
          <ModalBody>
            {this.state.textOf === "privacy" ? <PrivacyPolicy /> : null}
            {this.state.textOf === "terms" ? null : null}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.setState({ showModal: false })}
            >
              Close
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </MainBg>
    );
  }
}

Register.propTypes = {
  onSuccess: func,
  setUser: func,
  setScores: func,
  gameUid: string,
  videoData: object,
  setVideo: func,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setUser, setScores, setVideo }, dispatch);

const mapStateToProps = (state) => {
  return {
    videoData: state.video,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
