import React, { useEffect, useState } from "react";
// import { string } from "prop-types";
import {
  FacebookShareButton,
  TwitterShareButton,
  // EmailShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
} from "react-share";
import Api from "Api";
import config from "webconfig";
import { shallowEqual, useSelector } from "react-redux";
import EmailShareButton from "components/Utils/EmailShareButton";

const Share = () => {
  // const [imageLink, setImageLink] = useState("");
  const { scores, video } = useSelector((state) => {
    return {
      scores: state.scores,
      video: state.video,
    };
  });

  const myScore = scores?.find((x) => x.video_id === video?.uid);

  const twitterUrl =
    config.apiURL + `/share/twitter/${myScore?.certificate_name}/${video?.uid}`;

  const imageLink =
    "https://gamified.easytours.com.ph/files/" +
    video.uid +
    "/certificate/" +
    myScore?.certificate_name;

  const settings = video.settings;

  console.log("myScore ", myScore);

  return (
    <div className="Demo__container text-left">
      <div className="Share-wrapper-container">
        <FacebookShareButton
          url={imageLink}
          quote={settings?.shareMessage || "Check out my certificate!"}
          className="Share-wrapper-container__share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>

      <div className="Share-wrapper-container">
        <TwitterShareButton
          url={twitterUrl}
          title={settings?.shareTitle || "Check out my certificate!"}
          className="Share-wrapper-container__share-button"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <div className="Share-wrapper-container__share-count">&nbsp;</div>
      </div>

      <div className="Share-wrapper-container">
        <EmailShareButton
          url={imageLink}
          subject="My Easytours Virtual Learning Journeys Badge"
          body={
            settings.shareMessage
              ? `${settings.shareMessage} ->`
              : "Check out my certificate as an Ocean Warrior. ->"
          }
          className="Share-wrapper-container__share-button"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default Share;
